 /* EDUCATION GRADUATION RATE PAGE */

 .toggle-button {
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: var(--white);
    color: var(--primary-color);
    border-radius: 10px;
    margin: 0.5rem 1rem;
    padding: .5rem 2rem;
    border: none;
    width: 10rem;
}

.button-container {
    display: flex;
    justify-content: center;
    background-color: var(--light-blue);
    padding: .25rem;
    margin: 1rem 0 0;
    min-width: 60%;
    border-radius: 10px;
    flex-wrap: wrap;
}

.toggle-button:hover, .active {
    background-color: var(--blue);
    color: var(--white);
    text-decoration: none;
    transition: .4s ease;
}

.not-active {
    background-color: var(--white);
    color: var(--primary-color);
}