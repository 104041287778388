footer {
    display: flex;
    padding: .75rem;
    width: 100%;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--white);
    position: relative;
    bottom: 0;
    font-family: var(--secondary-font);
}

.footer-contact {
    flex: 0 1 60%;
    gap: .5rem;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.footer-logo {
    height: 1.5rem;
    width: auto;
    padding: .2rem 1rem;
    background-color: var(--white);
    border-radius: 10px;
}

.footer-right {
    flex: 0 1 40%;
    flex-wrap: wrap;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    display: flex;
    gap: 2rem;
    margin-right: 1rem;
}

.footer-right a {
    color: var(--white);
    text-decoration: none;
    transition: color .2s ease;
}

.footer-right a:hover {
    color: var(--yellow);
}

.footer-right img {
    width: 150px;
    transition: .3s ease all;
}

@media only screen and (max-width: 856px) {
    footer { 
        flex-wrap: wrap; 
    }
    .footer-contact {
        flex: auto; 
        justify-content: center;
    }

    .footer-right {
        flex: auto; 
        justify-content: center;
    }

  }