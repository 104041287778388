
.flex {
    display: flex;
}

.notes-container, .chart-container {
    display: flex;
    flex-direction: column;
    letter-spacing: .02em;
}

.chart-container {
    width: 65%;
    flex: 1 0 auto;
}

.notes-container {
    width: 35%;
}

.header {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 1.5rem 1.5rem 0rem;
    color: var(--primary-color);
}

.gap-class {
    margin: 1.5rem;
}

.value-box, .notes {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 2rem 1rem;
    border-radius: 10px;
    
}

.value-box {
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
    min-height: 10rem;
}

.notes {
    background-color: var(--light-blue);
    color: var(--primary-color);
    /* margin-right: 1rem; */
}

.big-font {
    font-size: 2.5rem;
}


@media only screen and (max-width: 1300px) {
    .chart-container {
        width: 100%;
    }

    .notes-container {
        width: 100%;
    }
    
    .flex {
      flex-direction: column;
    }
  }