
.about-header {
  text-align: center;
  margin: 0rem 1.5rem 0rem;
  color: var(--primary-color);
}

.logo-img {
  max-width: 6rem;
  max-height: 6rem;
  margin-right: 1rem;
}

.parent-container {
  background-color: var(--light-blue);
  padding: 1.5rem 2rem;
  border-radius: 10px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.child-container {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}

@media only screen and (max-width: 500px) {
  .logo-img {
    display: flex;
    margin-right: 0;
  }

  .child-container {
    flex-direction: column;
  }
}
