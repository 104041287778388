.main-container {
    background-color: var(--yellow);
    display: flex;
    padding: 1.05rem 1.71rem;
    /* max-width: 100% */
}

.button-flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.button-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--white);
    color: var(--primary-color);
    padding: 1.05rem;
    border-radius: 10px;
    font-size: 1.25rem;

    /* Likely need to adjust */
    height: 4.5rem;
    width: 10rem;
    /* max-width allows responsiveness in buttons but set width makes all buttons equal size*/
    /* max-width: 10rem; */
    /* flex: 1 1 auto; */
}

.button-link:hover {
    text-decoration: none;
    transition: 0.4s ease;
}

.button-link:hover, .active {
    background-color: var(--blue);
    color: var(--white);
}

/* Mobile Navbar Toggler */

.navbar-toggler {
    margin-right: 1.71rem;
    max-height: 4.5rem;
    max-width: 58px;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(30,59,96)' stroke-width='2px' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  
.navbar-light .navbar-toggler {
    /* border: 2px solid var(--primary-color); */
    background-color: var(--white);
}

@media screen and (max-width: 630px) {

    .main-container {
        flex-direction: column;
    }

    .button-flex {
        margin-top: 1.71rem;
    }
  
  }
