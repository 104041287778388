/* ACHIEVEMENT SCORES PAGE */

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0rem 0rem;
  min-width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}

.dropdown-container {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.interaction-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--light-blue);
  margin: 1rem 0rem 0rem;
  border-radius: 10px;
}

/* CUSTOM SELECT */

/* removes default select styles */
select {
  appearance: none;
  outline: none;
  z-index: 1;
  background-color: transparent;
  border: none;
  min-width: 10rem;
  margin: 0;
  padding: .5rem 1rem;
  font-size: inherit;
  cursor: inherit;
}
select::-ms-expand {
  display: none;
}

/* custom select styles */
.label {
  margin: .5rem;
  font-size: 1.2rem;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  background-color: var(--white);
  border-radius: 10px;
  margin: .5rem 1rem;
}

.select select, .select::after {
  grid-area: select;
}

/* Custom arrow for select */
.select:not(.select--multiple)::after {
  content: "";
  justify-self: end;
  width: 1.25em;
  height: .75em;
  margin-right: 1rem;
  background-color: var(--primary-color);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

@media screen and (max-width: 1300px) {
  .interaction-container {
    flex-direction: column;
  }

  .buttons-container {
    margin: 0.5rem 0 .25rem;
  }

  .dropdown-container{
    margin: 0.25rem 0 .5rem;
  }
  
}
