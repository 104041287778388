
.navbar {
    display: flex;
    /* height: 143px; */
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
}

.navbar-center {
    display: flex;
    align-items: center;
    background-color: var(--white);
    height: 114px;
    border-radius: 20px;
    padding: 20px;
    margin: 0 auto;
}

.logo-text {
    color: var(--green);
    font-size: 46px;
    font-weight: 600;
    margin-left: 30px;
    margin-right: 30px;
}


@media screen and (max-width: 768px) {
    .logo-text {
         font-size: 20px;
    }
     
 }

@media screen and (max-width: 500px) {
    .logo-text {
         font-size: 18px;
    }
     
}

@media screen and (max-width: 360px) {
    .logo-text {
         font-size: 16px;
    }
     
}

@media screen and (max-width: 320px) {
    .logo-text {
         font-size: 14px;
    }
     
}

@media screen and (max-width: 280px) {
    .logo-text {
         font-size: 12px;
    }
     
}