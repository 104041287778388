
.indicator-icon {
    fill: var(--primary-color);
    height: 60px;
    width: 65px;
}

.top-level,
.bottom-level {
    display: flex;
}

.indicator {
    background-color: var(--light-blue);
    margin: 20px;
}

.top-level .indicator {
    min-height: 400px;
    min-width: 240px;
}

.bottom-level .indicator {
    min-height: 320px;
    min-width: 240px;
}

.indicator-main {
    display: flex;
    padding: 10px 20px 0px 20px;
    
}

.indicator-header {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 38px;
    font-weight: 200;
    height: 70px;
}

.indicator-inner-box {
    background-color: var(--white);
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}

.section-left {
    margin-right: 20px;
    width: 100%;
}

.section-right {
    width: 100%;
}

.indicator-inner-box {
    margin-bottom: 20px;
}

.stats-value {
    font-size: 45px;
    font-weight: bold;
}

.stats-value span {
    font-size: 21px;
    margin-left: 10px;
}

.stats-caption {
    font-size: 20px;
    font-weight: 200;
    align-self: center;
    color: var(--primary-color);
    padding: 0 10px 10px 10px;
    height: 40%;
}

@media screen and (max-width: 1594px) {

    .top-level, .bottom-level {
        display: block;
    }

}

@media screen and (max-width: 856px) {

    .indicator-main {
        display: block;
        padding-bottom: 2px;
    }

}

@media screen and (max-width: 360px) {

    .stats-caption {
        font-size: 14px;
    }

}




